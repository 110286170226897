(function($) {

    $("#search-toggler").click(function(){
      $("#custom-search").slideToggle();
      if($(this).hasClass('toggled')) {
        $(this).removeClass('toggled');
        $(this).focus();
      } else {
        $(this).addClass('toggled');
        $(".oiva-custom-search-input").focus();
      }
    });

  // Custom search form submit
  $('#oiva-custom-search-form, #oiva-quick-search-form, #oiva-box-custom-search-form').submit(function(e) {

    $form = $(this);

    var url = $form.attr('action');
    var value = $form.find('input').val();
    if (value) {
      /*value = value.replace(/&/gm, '&amp;')
                   .replace(/\n/gm, "&#10;")
                   .replace(/\r/gm, "&#13;")
                   .replace(/</gm, '&lt;')
                   .replace(/>/gm, '&gt;')
                   .replace(/"/gm, '&quot;')
                   .replace(/'/gm, '&apos;') //single quotes just to be safe
                   .replace(/ä/gm, "%E4")
                   .replace(/Ä/gm, "%C4")
                   .replace(/ö/gm, "%F6")
                   .replace(/Ö/gm, "%D6")
                   .replace(/å/gm, "%E5")
                   .replace(/å/gm, "%C5")
                   .replace(/é/gm, "%E9&")
                   .replace(/ /gm, "%20");*/

      value = '#text=' + value;

    } else {

      value = '#';

    }

    var current_url = window.location.href;

    var full_url = url + value;
    window.location.href = full_url;

    // If new url is same as current, reload
    if (current_url.indexOf(url) >= 0) {
      window.location.reload();
    }
    return false; // don't submit
  });

  // Quick search focus

  $( ".oiva-quick-search-input" ).focusin(function() {
    $(this).parents('form').addClass('focused');
  });

  $( ".oiva-quick-search-input" ).focusout(function() {
    $(this).parents('form').removeClass('focused');
  });

  // Fallback svg to png

  if (!Modernizr.svg) {
    var imgs = document.getElementsByTagName('img');
    var svgExtension = /.*\.svg$/;
    var l = imgs.length;
    for(var i = 0; i < l; i++) {
      if(imgs[i].src.match(svgExtension)) {
        imgs[i].src = imgs[i].src.slice(0, -3) + 'png';
      }
    }
  }
/*
  // Sub-pages navigation

  $('.sub-pages-navigation').find('.page_item_has_children').click(function(e) {
     if(e.target.tagName === 'LI') {

       if( $(this).hasClass('current_page_ancestor') ||  $(this).hasClass('current_page_item') ) {
         if(!$(this).hasClass('active') && $(this).hasClass('hide') ) {
           $(this).addClass('hide');
         } else {
           $(this).toggleClass('hide');
           $(this).toggleClass('active');
         }
       } else {
         $(this).toggleClass('active');
       }

       // don't trigger parent(s)
       e.stopPropagation();
     }
  });
*/

})(jQuery);
